/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import PhotoGallery from 'react-photo-gallery';
import Fade from 'react-reveal/Fade';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { nanoid } from 'nanoid';
import Title from '../Title/Title';

import work from '../../images/work.jpg';
import ACMLocal1 from '../../images/ACMLocal1.jpg';
import CodeCup1 from '../../images/CodeCup1.jpg';
import CodeCup2 from '../../images/CodeCup2.jpg';
import CodeCup3 from '../../images/CodeCup3.jpg';
import CodeCup4 from '../../images/CodeCup4.jpg';
import Rahnema1 from '../../images/Rahnema1.jpg';
import Rahnema2 from '../../images/Rahnema2.jpg';
import Rahnema3 from '../../images/Rahnema3.jpg';
import Rahnema4 from '../../images/Rahnema4.jpg';

const galleryData = [
  {
    id: nanoid(),
    src: work,
    width: 4032,
    height: 1960,
    title: 'Digikala Co - Technology Team',
  },
  {
    id: nanoid(),
    src: CodeCup1,
    width: 1280,
    height: 853,
    title: 'CodeCup4 - In Digikala fulfillment center',
  },
  {
    id: nanoid(),
    src: CodeCup2,
    width: 1280,
    height: 853,
    title: 'CodeCup4 - In Digikala fulfillment center',
  },
  {
    id: nanoid(),
    src: ACMLocal1,
    width: 4608,
    height: 3456,
    title: 'Shahid Beheshti University - ACM Newbies Local Contest',
  },
  {
    id: nanoid(),
    src: CodeCup3,
    width: 1280,
    height: 853,
    title: 'CodeCup4 - In Digikala fulfillment center',
  },
  {
    id: nanoid(),
    src: CodeCup4,
    width: 1280,
    height: 853,
    title: 'CodeCup4 - In Digikala fulfillment center',
  },
  {
    id: nanoid(),
    src: Rahnema1,
    width: 5184,
    height: 3456,
    title: 'Ranhema College Birthday',
  },
  {
    id: nanoid(),
    src: Rahnema2,
    width: 5184,
    height: 2912,
    title: 'Ranhema College Software Engineering Internship',
  },
  {
    id: nanoid(),
    src: Rahnema3,
    width: 5184,
    height: 2912,
    title: 'Ranhema College Software Engineering Internship',
  },
  {
    id: nanoid(),
    src: Rahnema4,
    width: 4032,
    height: 3024,
    title: 'Ranhema College Software Engineering Internship',
  },
];

let photos;

const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

function Gallery() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  useEffect(() => {
    if (window.innerWidth > 769) {
      photos = shuffle(galleryData);
    } else {
      photos = shuffle(galleryData).slice(0, 4);
    }
  }, []);

  return (
    <section id="gallery">
      <Container>
        <Title title="Gallery" />
        <Fade bottom duration={1000} delay={800} distance="30px">
          {photos && (
            <>
              <PhotoGallery photos={photos} onClick={openLightbox} />
              <ModalGateway>
                {viewerIsOpen ? (
                  <Modal onClose={closeLightbox}>
                    <Carousel
                      currentIndex={currentImage}
                      views={photos.map((x) => ({
                        ...x,
                        srcset: x.srcSet,
                        caption: x.title,
                      }))}
                    />
                  </Modal>
                ) : null}
              </ModalGateway>
            </>
          )}
        </Fade>
      </Container>
    </section>
  );
}

export default Gallery;
