/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Typed from 'typed.js';
import { Link } from 'react-scroll';
import { Parallax } from 'react-parallax';
import { useSpring, animated } from 'react-spring';
import { FaLinkedin, FaGithub, FaYoutube, FaAngleDown } from 'react-icons/fa';
import { SiSlides } from 'react-icons/si';
import { BsCloudDownload } from 'react-icons/bs';
import PortfolioContext from '../../context/context';

import HeroBackground from '../../images/hero-bg.jpg';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta, networks } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const options = {
      strings: subtitle || [' '],
      typeSpeed: 30,
      backSpeed: 15,
      smartBackspace: true,
      shuffle: true,
      loop: true,
      backDelay: 1700,
    };

    const typed = new Typed('#typed-js', options);

    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
    return () => {
      typed.destroy();
    };
  }, [subtitle]);

  return (
    <animated.div style={useSpring({ opacity: 1, from: { opacity: 0 } })}>
      <Parallax
        blur={{ min: -5, max: 5 }}
        bgImage={HeroBackground}
        bgImageAlt="Main Image"
        strength={50}
        bgImageStyle={{
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          zIndex: -1,
          position: 'fixed',
        }}
      >
        <section
          style={{
            backgroundColor: '#111',
            background: 'linear-gradient(to bottom,transparent 0,rgba(0,0,0,.8) 100%)',
            borderRadius: 0,
          }}
          id="hero"
          className="jumbotron"
        >
          <Container>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
              <h1 className="hero-title">
                {title} <br /> I am <span className="text-color-main">{name}</span>
                <br />
                <span id="typed-js" />
              </h1>
            </Fade>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <p className="hero-cta">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cta-btn cta-btn-hero--resume"
                  href="/Parsa_Hejabi_Resume.pdf"
                >
                  Download CV
                  <BsCloudDownload className="cta-btn-download" />
                </a>
                <span style={{ marginLeft: '1vw' }} className="cta-btn cta-btn--hero">
                  <Link to="about" smooth duration={1000}>
                    {cta}
                    <FaAngleDown className="cta-btn-angle-down" />
                  </Link>
                </span>
              </p>
            </Fade>
            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <ul className="home-social">
                {networks &&
                  networks.map((network) => {
                    const { id, networkName, url } = network;
                    return (
                      <li key={id}>
                        <a
                          href={url}
                          rel="noopener noreferrer"
                          target="_blank"
                          aria-label={networkName}
                          className={`home-social-${networkName}`}
                        >
                          {networkName === 'LinkedIn' ? (
                            <FaLinkedin />
                          ) : networkName === 'GitHub' ? (
                            <FaGithub />
                          ) : networkName === 'YouTube' ? (
                            <FaYoutube />
                          ) : (
                            <SiSlides />
                          )}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </Fade>
          </Container>
        </section>
      </Parallax>
    </animated.div>
  );
};

export default Header;
