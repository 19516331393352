/** @jsx jsx */
import { useContext } from 'react';
import { css, jsx } from '@emotion/core';
import PortfolioContext from '../../context/context';

const SkillBar = () => {
  const { about } = useContext(PortfolioContext);
  const { skills } = about;

  return (
    <div className="about-wrapper__info">
      <div
        className="about-wrapper__info-interest"
        css={css`
          width: 100%;
        `}
      >
        <h1>Selected Skills</h1>

        {skills &&
          skills.map((skill) => {
            const { skillName, level, id } = skill;
            return (
              <div key={id} className="bar">
                <h2>{skillName}</h2>
                <span
                  css={css`
                    &:before {
                      width: ${level}%;
                    }
                  `}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SkillBar;
