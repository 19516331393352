import React, { useContext, useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Container, Row, Col } from "react-bootstrap";
import { BsCloudDownload } from "react-icons/bs";
import Title from "../Title/Title";
import AboutImg from "../Image/AboutImg";
import PortfolioContext from "../../context/context";
import SkillBar from "./SkillBar";

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="About Me" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="Profile Picture" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade
              right={isDesktop}
              bottom={isMobile}
              duration={1000}
              delay={1000}
              distance="30px"
            >
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text text-link">
                  I am a Master of Science (M.S.) student in computer science
                  (Artificial Intelligence) at the{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.usc.edu/"
                    className="about-wrapper__info-link"
                  >
                    University of Southern California
                  </a>
                  .
                  <ul>
                    <li>
                      <b>Cumulative GPA:</b> 3.83/4.0
                    </li>
                    <li>
                      Currently an NLP researcher at{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.isi.edu/directory/phejabi/"
                        className="about-wrapper__info-link"
                      >
                        USC Information Sciences Institute
                      </a>
                      .
                    </li>
                  </ul>
                </p>
                <p className="about-wrapper__info-text text-link">
                  I have got my Bachelor of Science (B.S.) in computer
                  engineering from{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://en.sbu.ac.ir/SitePages/Home.aspx"
                    className="about-wrapper__info-link"
                  >
                    Shahid Beheshti University
                  </a>
                  .
                  <ul>
                    <li>
                      Received full scholarship from Shahid Beheshti University
                      (tuition waiver)
                    </li>
                    <li>
                      <b>Cumulative GPA:</b> 3.69/4
                      <ul>
                        <li>
                          Ranked within the <b>top 3 GPAs</b> among my
                          graduating class
                        </li>
                      </ul>
                    </li>
                    <li>
                      <b>Thesis Title:</b> Information extraction from users’
                      requests in <b>“SOHA”</b>
                      <ul>
                        <li>
                          <b>“Best thesis project candidate”</b> in{" "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="http://en.sbu.ac.ir/SitePages/Home.aspx"
                            className="about-wrapper__info-link"
                          >
                            Shahid Beheshti University
                          </a>{" "}
                          Faculty of Computer Science and Engineering
                        </li>
                      </ul>
                    </li>
                  </ul>
                </p>
                <p className="about-wrapper__info-text text-link">
                  I started programming computers at the age of 11 with Pascal
                  programming language at Allameh Helli junior high school, a
                  branch of the National Organization for Development of
                  Exceptional Talents (NODET). Programming has been my passion
                  since then. Later, I have continued programming in Allameh
                  Helli high school, and I chose computer engineering as a
                  university major.
                </p>
                <p className="about-wrapper__info-text text-link">
                  I plan to continue my education path and simultaneously gain
                  more experience in the industry, directly applying what I am
                  learning to real-world problems and devise new solutions for
                  them.
                </p>
                <p className="about-wrapper__info-text text-link">
                  <h1>English Language Proficiency</h1>
                  <h2>IELTS Academic Scores:</h2>
                  <b>Overall (7.5)</b>
                  <ul>
                    <li>Listening (8.0)</li>
                    <li>Reading (7.5)</li>
                    <li>Writing (6.5)</li>
                    <li>Speaking (7.0)</li>
                  </ul>
                </p>
                <span className="d-flex mt-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cta-btn cta-btn--resume"
                    href="/Parsa_Hejabi_Resume.pdf"
                  >
                    Download CV
                    <BsCloudDownload className="cta-btn-download" />
                  </a>
                </span>
              </div>
            </Fade>
          </Col>
        </Row>
        <Row
          className="about-wrapper"
          style={{ marginTop: "2rem", paddingBottom: "0" }}
        >
          {/* <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <div className="about-wrapper__info-interest">
                  <h1>Research Interests</h1>
                  <h2>Artificial Intelligence</h2>
                  <ul>
                    <li>Natural Language Processing</li>
                    <li>Computer Vision</li>
                    <li>Expert Systems</li>
                    <li>Robotics</li>
                  </ul>
                  <h2>Software Engineering</h2>
                  <ul>
                    <li>Software Engineering & Computer Programming</li>
                    <li>Computer Networks</li>
                    <li>Operating Systems</li>
                    <li>Human-Computer Interaction</li>
                  </ul>
                </div>
              </div>
            </Fade>
          </Col> */}
          <Col md={12} sm={12}>
            <Fade
              right={isDesktop}
              bottom={isMobile}
              duration={1000}
              delay={1000}
              distance="30px"
            >
              <SkillBar />
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
