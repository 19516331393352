import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Title from '../Title/Title';

import ReactNativeCert from '../../images/DigikalaReactNativeCertificate.jpg';
import StructuringMLProjectsCert from '../../images/StructuringMLProjects.jpg';
import NeuralNetworksCert from '../../images/NeuralNetworksDeepLearningCert.jpg';
import MATLABCert from '../../images/MATLABCert.jpg';
import RahnemaCollegeCert from '../../images/RahnemaCollegeCert.jpg';
import HyperParameterCert from '../../images/HyperparameterTuningCert.jpg';
import Java2017Cert from '../../images/Java2017Cert.png';
import Java2018Cert from '../../images/Java2018Cert.png';
import IEEENLPCert from '../../images/IEEENLPCertificate.jpg';

function Certificates() {
  return (
    <section id="certificates">
      <Container>
        <Title title="Certificates" />
        <Fade bottom duration={1000} delay={800} distance="30px">
          <AwesomeSlider animation="scaleOutAnimation">
            <div data-src={NeuralNetworksCert} />
            <div data-src={HyperParameterCert} />
            <div data-src={StructuringMLProjectsCert} />
            <div data-src={IEEENLPCert} />
            <div data-src={RahnemaCollegeCert} />
            <div data-src={ReactNativeCert} />
            <div data-src={Java2018Cert} />
            <div data-src={Java2017Cert} />
            <div data-src={MATLABCert} />
          </AwesomeSlider>
        </Fade>
      </Container>
    </section>
  );
}

export default Certificates;
